import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/Projects/img12.jpeg";
import Tilt from "react-parallax-tilt";
import {
  AiFillInstagram,
} from "react-icons/ai";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
            Kanjoor Mana <span className="purple"> Sree Chathan Kandar Kavu </span> Temple Story
            </h1>
            <p className="home-about-body">
              പാലക്കാട് ജില്ലയിലെ ഒറ്റപ്പാലത്ത് സ്ഥിതി ചെയ്യുന്ന പുരാതന ക്ഷേത്രമാണ് ചാത്തൻ കണ്ടർ കാവ്. ദുർഗ്ഗാ ദേവിക്ക് സമർപ്പിച്ചിരിക്കുന്ന ഈ ക്ഷേത്രം കാഞ്ഞൂർ നമ്പൂതിരിയാണ് നിർമ്മിച്ചതെന്ന് വിശ്വസിക്കപ്പെടുന്നു. ഹിന്ദു പുരാണമനുസരിച്ച്, താഴ്ന്ന ജാതിയിൽപ്പെട്ട ചാത്തൻ ഒരു കല്ലിൽ കത്തി മൂർച്ച കൂട്ടുകയായിരുന്നു. ഇവിടെ ഏതോ അമാനുഷിക സാന്നിധ്യമുണ്ടെന്ന് കാണിച്ച് കല്ല് പെട്ടെന്ന് രക്തസ്രാവം തുടങ്ങി. കുറച്ച് ദിവസങ്ങൾക്ക് ശേഷം ഈ സ്ഥലത്ത് ഒരു ക്ഷേത്രം നിർമ്മിച്ചു. മീനത്തിലെ അവിട്ടം നാളിൽ (മാർച്ച്-ഏപ്രിൽ) ആഘോഷിക്കുന്ന താലപ്പൊലി മഹോത്സവമാണ് വൻ ഭക്തജനങ്ങൾ പങ്കെടുക്കുന്ന പ്രധാന ഉത്സവം.
              <br /><br />
              താഴെ കാവിൽ ശാന്തസ്വരുപിണിയും മേലെ കാവിൽ ഉഗ്രസ്വരൂപിണിയായും വടക്കോട്ട് ദർശനമായി ഇരിക്കുന്ന, താഴെ കാവിനു നേരെ മുന്നിൽ തന്നെ മേലെ കാവിലമ്മ ഇരിക്കുന്ന ഒരെ ഒരു ക്ഷേത്രം എന്ന പ്രത്യേക കൂടിയുണ്ട് ശ്രീ ചാത്തൻ കണ്ട കാളി കാവിന്. 

ആന എഴുന്നള്ളിപ്പ് ഇല്ലാത ഒരു ക്ഷേത്രം കൂടിയാണ് ശ്രീ ചാത്തൻ കണ്ടാർകാവ് (ഭഗവതിക്ക് ആനയെ പേടിയാണ് എന്ന് സങ്കൽപ്പം നില നിൽക്കുന്നു. പണ്ട് കാലത്തും, കാഞ്ഞിയൂർ മനയ്ക്കൽ ആന ഉള്ളപ്പോഴും ക്ഷേത്രത്തിൽ എഴുന്നള്ളിപ്പ് ഇല്ല).

മീനമാസത്തിലെ മകം നാൾ പുനപ്രതിഷ്ഠാദിനമായി ആചരിക്കുന്നു.
              <br />
              <br />കൊടിയേറ്റത്തിനുമുണ്ട് പ്രത്യേകത, മീനത്തിലെ മകം രാത്രി 12 മണിക്ക് വരുബോൾ ദേശക്കാരെയും സ്ഥാനീയരെയും മനക്കാരെയും വിളിച്ചുച്ചൊല്ലി കൊടിയേറ്റം അങ്ങിനെയാണ് പതിവ്. താഴെ കാവിലെയും, മേലെ കാവിലെയും കൊടിമരത്തിൽ ഒരെ സമയം കൊടിയേറ്റം നടക്കും.
              <br />
              <br />
              കൂത്തുമുളക്ക് മുന്നെ കളംപാട്ടും പാട്ടുതാലാപ്പൊലിയും ആഘോഷിക്കുന്നു.
              <br />
              <br />
              കൊടിയേറ്റദിവസം അത്താഴപൂജക്കുശേഷം തട്ടകത്തമ്മയുടെ മക്കൾ ഭഗവതിക് സമർപ്പിക്കുന്ന ആട്, കോഴി, പശു, വിളക്കുകൾ അടക്കം എല്ലാം ലേലം ചെയ്യുന്ന പതിവ് ഇന്നും നിലനിന്നുപോരുന്നു.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            {/* <h1>Other Pages</h1> */}
            <p>
              Feel free to <span className="purple">Check </span>
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/prasanthmohan4419"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
