import React, { useState } from 'react';
import { Container, Carousel } from "react-bootstrap";
import { useMediaQuery } from '@material-ui/core';

const image1 = require('../Assets/Projects/img1.jpeg');
const image2 = require('../Assets/Projects/img2.jpeg');
const image3 = require('../Assets/Projects/img3.jpeg');
const image4 = require('../Assets/Projects/img4.jpeg');
const image5 = require('../Assets/Projects/img5.jpeg');
const image6 = require('../Assets/Projects/img6.jpeg');
const image7 = require('../Assets/Projects/img7.jpeg');
// const image8 = require('../Assets/Projects/img8.jpeg');
// const image9 = require('../Assets/Projects/img9.jpeg');
// const image10 = require('../Assets/Projects/img10.jpeg');


function ImageGallerySlideShow() {
  const tileData = [
    {
      img: image1,
      title: 'Image 1',
      author: 'author',
    },
    {
      img: image2,
      title: 'Image 2',
      author: 'author',
    },
    {
      img: image3,
      title: 'Image 1',
      author: 'author',
    },
    {
      img: image4,
      title: 'Image 3',
      author: 'author',
    },
    {
      img: image5,
      title: 'Image 1',
      author: 'author',
    },
    {
      img: image6,
      title: 'Image 4',
      author: 'author',
    },
    {
      img: image7,
      title: 'Image 5',
      author: 'author',
    },
  ];
  
  
  const [index, setIndex] = useState(0);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          Recent <strong className="purple">Event photos </strong>
        </h1>
        {/* <p style={{ color: "white" }}>
          Recent event photos added987@Welcome
        </p> */}
        <Carousel 
          activeIndex={index} 
          onSelect={handleSelect}
          style={{ width: '80%', margin: '0 auto' }}
          >
          {tileData.map((tile) => (
            <Carousel.Item key={tile.img} style={{ width: '100%', objectFit: 'cover' }}>
              <img src={tile.img} alt={tile.title}
                 className="d-block w-100"
                 style={{ width: '100%', height: isSmallScreen ? '200px' : '500px', objectFit: 'cover' }}
               />
              <Carousel.Caption>
                {/* <h3>{tile.title}</h3>
                <p>by: {tile.author}</p> */}
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>

    </Container>
  );
}

export default ImageGallerySlideShow;
